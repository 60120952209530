import React, { useCallback, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import { getChainSymbol, MiningPoolAbi, tokenAbi } from "../../../../utils/config";
import { eventBus } from "../../../../utils/tool";
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';


export default function StakeModal(props) {
    const [errorMsg, setErrorMsg] = useState(''); // 错误信息
    const [currentProvider, setCurrentProvider] = useState(null); // 当前的provider

    const [rate, setRate] = useState(['']); // 推广税率
    const [submitLoading, setSubmitLoading] = useState(false); // 提交按钮loading
    const [tmpRate, setTmpRate] = useState({}); // 临时的推广税率, level=>rate
    const chainId = useSelector(state => state.chainId);
    const { t } = useTranslation();
    useImperativeHandle(props.onRef, () => {
        // 需要将暴露的接口返回出去
        return {
        };
    });



    const initProvider = () => {
        if (currentProvider) {
            return currentProvider
        }
        if (window.ethereum && !currentProvider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            setCurrentProvider(provider)
            return provider
        } else {
            console.log('No provider')
        }
    }


    const handleErr = (e) => {
        let t = ''
        if (e.reason) {
            t = e.reason
        } else {
            t = JSON.stringify(e)
        }
        setErrorMsg(t)
    }

    useEffect(() => {
        if (props.invitorRewardPercentList && props.invitorRewardPercentList.length > 0) {
            setRate(props.invitorRewardPercentList)
            let tmp = {}
            props.invitorRewardPercentList.forEach((item, ind) => {
                tmp[ind] = item
            })
            setTmpRate(tmp)
        }
    }, [props.invitorRewardPercentList])


    return <>
        <Modal centered show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            {/* <Modal.Header closeButton>
                <Modal.Title>开启邀请推广</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div>
                    <Alert>
                        <div>
                            {t('设置推广算力, 根据下级质押代币的数量, 提升上级的算力, 最高可实现7代推广')}
                        </div>
                        <div>
                            {t('例如: 1级算力5% 下级质押了1000个代币 那么上级将会获取1000 * 5% = 50的算力')}
                        </div>

                    </Alert>
                    {/* 一级推广税率 */}
                    <Form>
                        {rate && rate.length > 0 && rate.map((item, ind) => {
                            return <Form.Group key={'rem' + ind} >
                                <InputGroup className="mb-3">
                                    <Form.Control value={tmpRate[ind]} onChange={(e) => {
                                        // let tmp = tmpRate
                                        // tmp[ind] = e.target.value
                                        // setTmpRate(tmp)
                                        setTmpRate(old => {
                                            return {
                                                ...old,
                                                [ind]: e.target.value
                                            }
                                        })

                                    }} key={item + ind + 'aa'} className="ipfs-info-input" type="text" placeholder={t('1级邀请提升算力比例(%)').replace('{index}', ind + 1)} />
                                    {ind > 0 && <InputGroup.Text onClick={() => {
                                        let arr = rate
                                        arr.splice(ind, 1)
                                        setRate(arr)
                                        let tmp = tmpRate
                                        tmp[ind] = ''
                                        setTmpRate(tmp)
                                    }} className="ipfs-info-input"    >
                                        <i style={{ 'cursor': 'pointer' }} className="fas fa-trash-alt"></i>
                                    </InputGroup.Text>}
                                </InputGroup>
                            </Form.Group>
                        }
                        )}
                        <div className="d-flex flex-row align-items-center justify-content-between mt-2 ">
                            {/* 新增下级按钮 */}
                            {rate.length < 7 && <div onClick={() => {
                                if (rate.length >= 7) {
                                    setErrorMsg(t('最多可设置7级推广'))
                                    return
                                }
                                let arr = rate
                                arr.push('')
                                setRate(arr)
                            }} className="btn btn-bordered-white btn-smaller">
                                {t('新增下级')}
                            </div>}
                            {!props.open && <div style={{ 'fontSize': '15px', 'fontWeight': 600 }}>
                                {t('费用')}:  {props.fee} {getChainSymbol(chainId)}
                            </div>}
                        </div>
                    </Form>
                    <div className="mt-2">
                        <Alert variant="danger" show={errorMsg ? true : false} >
                            {errorMsg}
                        </Alert>
                    </div>
                    <div className="mt-2 d-flex flex-row align-items-center   justify-content-center">

                        <div onClick={async () => {
                            setErrorMsg('')
                            let xArr = rate
                            for (let n in tmpRate) {
                                if (n > rate.length - 1) {
                                    continue
                                }
                                xArr[n] = tmpRate[n]
                            }
                            setRate(xArr)

                            // 验证信息
                            if (rate.length < 1) {
                                setErrorMsg(t('请设置推广算力'))
                                return
                            }
                            let total = 0
                            let flag = true
                            rate.forEach((item, ind) => {
                                if (!item) {
                                    flag = false
                                    setErrorMsg(t('请设置1级推广算力').replace('{index}', ind + 1))
                                    return
                                }
                                if (parseFloat(item) < 0 || parseFloat(item) > 100) {
                                    flag = false
                                    setErrorMsg(t(`1级推广算力应在0-100之间`).replace('{index}', ind + 1))
                                    return
                                }
                                total += parseFloat(item)
                            })
                            if (!flag) {
                                return
                            }
                            if (total > 100) {
                                setErrorMsg(t('推广算力总和不能超过100%'))
                                return
                            }

                            let arr = []
                            rate.forEach((item) => {
                                arr.push(ethers.utils.parseUnits(item + '', 2))
                            })
                            // 提交
                            setSubmitLoading(true)
                            try {
                                const provider = initProvider()
                                const signer = provider.getSigner()
                                const contract = new ethers.Contract(props.poolAddress, MiningPoolAbi, signer)

                                if (props.open) {
                                    const tx = await contract.setInvitorRewardPercentList(arr)
                                    await tx.wait()
                                } else {
                                    // 未开启,走开启逻辑, 已开启, 走修改逻辑
                                    const tx = await contract.openInviter(arr, { value: ethers.utils.parseEther(props.fee + '') })
                                    await tx.wait()
                                }

                                // 执行ok
                                props.ok && props.ok()
                            } catch (e) {
                                handleErr(e)
                            }

                            setSubmitLoading(false)
                        }} className="ml-2 btn btn-bordered-white btn-smaller d-flex flex-row align-items-center   justify-content-center">
                            {submitLoading && <span>
                                <i style={{ 'fontSize': '13px', 'marginRight': '8px' }} className="fa fa-spinner loading-css"></i>
                            </span>}
                            {t('提交')}
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    </>
}