import { EventEmitter } from 'events';

export const eventBus = new EventEmitter()

var AWS = require('aws-sdk');

var albumBucketName = "token-monitor";
var bucketRegion = "us-east-1";


AWS.config.region = bucketRegion; // 区域
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:9d54ee94-24a7-4c6f-ba16-a35a515b5465',
});


// var s3 = new AWS.S3();
export function AwsUpdate(file, key = '', onProgressCb = null) {
    console.log(file)
    var upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: albumBucketName,
            Key: key,
            Body: file
        }
    });

    upload.on('httpUploadProgress', (x) => {
        // const percent = Math.round(x.loaded / x.total * 10000) / 100
        // console.log('上传进度是', percent, "%")
        onProgressCb && onProgressCb(x)
    })

    var promise = upload.promise();
    return promise
}
