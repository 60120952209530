import { create } from 'ipfs-http-client'

const ipfsUrl = 'https://ipfs.fatsale.org'
const ipfsGatewayUrl = 'https://ipfs.gateway.fatsale.org'
// 上传文件
export const uploadFile = async (file) => {
    const client = await create(ipfsUrl)
    const added = await client.add(file)
    console.log(added)
    return added
}

// 保存文字
export const uploadText = async (text) => {
    const client = await create(ipfsUrl)
    const added = await client.add(text)
    console.log(added)
    return added
}


export const getIpfsUrl = (key) => {
    return `${ipfsGatewayUrl}/ipfs/${key}`
}

// 获取保存的文字
export const getIpfsText = async (key) => {
    const url = getIpfsUrl(key)
    const resp = await fetch(url)
    const text = await resp.text()
    return text
}