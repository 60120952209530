import React, { useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

import { zeroAddress } from "../../../../utils/config";



export default function StakeModal(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const { t } = useTranslation();

    useEffect(() => {
        setPageCount(Math.ceil(props.total / props.perPage))
    }, [props.total, props.perPage])

    const getShortAddress = (address) => {
        return address.slice(0, 4) + "..." + address.slice(address.length - 4);
    }
    return <>
        <div>
            <Table style={{ 'backgroundColor': '#16182d' }} responsive hover variant="dark">
                <thead>
                    <tr>
                        <th className="user-list-table-th">{t('地址')}</th>
                        <th className="user-list-table-th">{t('已质押')}</th>
                        <th className="user-list-table-th">{t('总奖励')}</th>
                        <th className="user-list-table-th">{t('已领取奖励')}</th>
                        <th className="user-list-table-th">{t('已提升算力')}</th>
                        <th className="user-list-table-th">{t('邀请人')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.list?.length > 0 && props.list.map((item, ind) => {
                        return <tr key={'invitor-' + (props.key || '') + ind + item.userAddr}>
                            <td onClick={() => {
                                // 复制链接
                                props.copy && props.copy(item.userAddr)
                            }} className="user-list-table-th user-list-table-td">
                                <span>  {getShortAddress(item.userAddr)}</span>
                                <i className="fa fa-copy user-list-table-copy"></i>
                            </td>
                            <td className="user-list-table-th user-list-table-td">{(item.stakedTokenAmount / Math.pow(10, props.stakeDecimals)).toFixed(2)}</td>
                            <td className="user-list-table-th user-list-table-td">{(item.rewardTokenAmount / Math.pow(10, props.rewardDecimals)).toFixed(2)}</td>
                            <td className="user-list-table-th user-list-table-td">{(item.claimedTokenAmount / Math.pow(10, props.rewardDecimals)).toFixed(2)}</td>
                            <td className="user-list-table-th user-list-table-td">{(item.extendPower / Math.pow(10, props.stakeDecimals)).toFixed(2)}</td>
                            <td onClick={() => {
                                // 复制链接
                                props.copy && props.copy(item.userAddr)
                            }} className="user-list-table-th user-list-table-td">
                                {item.invitor !== zeroAddress && <span>
                                    <span >  {getShortAddress(item.invitor)}</span>
                                    <i className="fa fa-copy  user-list-table-copy"></i>
                                </span>}
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
            {pageCount > 1 && <div className="d-flex flex-row custom-paginate">
                {currentPage > 1 && <div onClick={() => {
                    setCurrentPage(currentPage - 1)
                    props.changePage && props.changePage(currentPage - 1)
                }} className="custom-paginate-item">
                    <i style={{ 'fontSize': '12px' }} className="fas fa-chevron-left"></i>
                </div>}
                {Array.from({ length: pageCount }, (v, k) => k).map((item, ind) => {
                    return <div key={'paginate-' + ind + (props.key || '')} onClick={() => {
                        setCurrentPage(ind + 1)
                        props.changePage && props.changePage(ind + 1)
                    }} className={"custom-paginate-item " + (currentPage === ind + 1 ? 'custom-paginate-item-active' : '')} >
                        {ind + 1}
                    </div>
                })}
                {currentPage < pageCount && <div onClick={() => {
                    setCurrentPage(currentPage + 1)
                    props.changePage && props.changePage(currentPage + 1)
                }} className="custom-paginate-item">
                    <i style={{ 'fontSize': '12px' }} className="fas fa-chevron-right"></i>
                </div>}
            </div>}
        </div>
    </>
}