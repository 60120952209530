import { ethers } from "ethers"

import { EventEmitter } from 'events'


export const BSC_TESTNET = 97
export const BSC_MAINNET = 56
export const SHIB_MAINET = 1923
export const ETHW_MAINNET = 10001
export const HECO_MAINNET = 128
export const FSC_MAINNET = 201022
export const TX_MAINNET = 8989
export const ETH_MAINNET = 1
export const DOJO_MAINNET = 10888
export const OKX_MAINNET = 66
export const SHJ_MAINNET = 88188 // 山海经
export const POLYGON_MAINNET = 137
export const CORE_MAINNET = 1116
export const ARB_MAINNET = 42161
export const CFX_MAINNET = 1030


export const MiningPoolAbi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_extendOpe",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Deposit",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "EmergencyWithdraw",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "poolLimitPerUser",
                "type": "uint256"
            }
        ],
        "name": "NewPoolLimit",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "rewardPerBlock",
                "type": "uint256"
            }
        ],
        "name": "NewRewardPerBlock",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "startBlock",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "endBlock",
                "type": "uint256"
            }
        ],
        "name": "NewStartAndEndBlocks",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "blockNumber",
                "type": "uint256"
            }
        ],
        "name": "RewardsStop",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "token",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "TokenRecovery",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "bool",
                "name": "isProfileRequested",
                "type": "bool"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "thresholdPoints",
                "type": "uint256"
            }
        ],
        "name": "UpdateProfileAndThresholdPointsRequirement",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Withdraw",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "returnEVENT",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "inviter",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "rewardAmount",
                "type": "uint256"
            }
        ],
        "name": "reward",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "inviter",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "updateInvitor",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "last",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "acc",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "sTS",
                "type": "uint256"
            }
        ],
        "name": "updatePoolSuccess",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "PRECISION_FACTOR",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "SMART_CHEF_FACTORY",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_baseURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "_invitor",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "_usersId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "accTokenPerShare",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "appove",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "bonusEndBlock",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "invitor",
                "type": "address"
            }
        ],
        "name": "deposit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "emergencyRewardWithdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "emergencyWithdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "enableInviter",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "extendTotalStakePower",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feeOfOpenInviter",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feeTo",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllUser",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "userAddr",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "invitAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "stakeAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "rewardAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "hasBeenClaimdReward",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "extendPower",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "invitor",
                        "type": "address"
                    }
                ],
                "internalType": "struct SmartChefInitializable.userExtendInfo[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getLockTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTotalPower",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTotalStakeAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUnlockTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "userId",
                "type": "uint256"
            }
        ],
        "name": "getUserById",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "userAddr",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "invitAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "stakeAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "rewardAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "hasBeenClaimdReward",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "extendPower",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "invitor",
                        "type": "address"
                    }
                ],
                "internalType": "struct SmartChefInitializable.userExtendInfo",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUserCanWithdraw",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUserTotalAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUserTotalRewardAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getUsersCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "hasUserLimit",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC20Metadata",
                "name": "_stakedToken",
                "type": "address"
            },
            {
                "internalType": "contract IERC20Metadata",
                "name": "_rewardToken",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_rewardPerBlock",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_startBlock",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_bonusEndBlock",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_poolLimitPerUser",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_numberBlocksForUserLimit",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_lengthOflockTime",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_admin",
                "type": "address"
            }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_feeTo",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_feeOfOpenInviter",
                "type": "uint256"
            }
        ],
        "name": "initialize_inviter",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "inviterListsForUser",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "userAddr",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "invitAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "stakeAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "rewardAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "hasBeenClaimdReward",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "extendPower",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "invitor",
                        "type": "address"
                    }
                ],
                "internalType": "struct SmartChefInitializable.userExtendInfo[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_ind",
                "type": "uint256"
            }
        ],
        "name": "inviterListsForUserPage",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "userAddr",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "invitAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "stakeAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "rewardAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "hasBeenClaimdReward",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "extendPower",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "invitor",
                        "type": "address"
                    }
                ],
                "internalType": "struct SmartChefInitializable.userExtendInfo",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "inviterListsForUserTotal",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "invitorRewardPercentList",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "isInitialized",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "lastRewardBlock",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "lenOfInvitorRewardPercentList",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "lengthOflockTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            },
            {
                "internalType": "bool",
                "name": "status",
                "type": "bool"
            }
        ],
        "name": "multiWLs",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "numberBlocksForUserLimit",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "newValue",
                "type": "uint256[]"
            }
        ],
        "name": "openInviter",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_user",
                "type": "address"
            }
        ],
        "name": "pendingReward",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "poolLimitPerUser",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_token",
                "type": "address"
            }
        ],
        "name": "recoverToken",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rewardPerBlock",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rewardToken",
        "outputs": [
            {
                "internalType": "contract IERC20Metadata",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "baseURI_",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "newValue",
                "type": "uint256"
            }
        ],
        "name": "setExtendTotalStakePower",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "newValue",
                "type": "uint256"
            }
        ],
        "name": "setFeeOfOpenInviter",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "newInvitor",
                "type": "address"
            }
        ],
        "name": "setInvitor",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256[]",
                "name": "newValue",
                "type": "uint256[]"
            }
        ],
        "name": "setInvitorRewardPercentList",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "newValue",
                "type": "uint256"
            }
        ],
        "name": "setLengthOflockTime",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bool",
                "name": "s",
                "type": "bool"
            }
        ],
        "name": "setUsercanemergencyWithdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "stakedToken",
        "outputs": [
            {
                "internalType": "contract IERC20Metadata",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "startBlock",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "stopReward",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bool",
                "name": "_userLimit",
                "type": "bool"
            },
            {
                "internalType": "uint256",
                "name": "_poolLimitPerUser",
                "type": "uint256"
            }
        ],
        "name": "updatePoolLimitPerUser",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_rewardPerBlock",
                "type": "uint256"
            }
        ],
        "name": "updateRewardPerBlock",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_startBlock",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_bonusEndBlock",
                "type": "uint256"
            }
        ],
        "name": "updateStartAndEndBlocks",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "userFirstDepositTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "userInfo",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "rewardDebt",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "extendPower",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "userLimit",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "usercanemergencyWithdraw",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "whitelistedUsers",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

export const tokenAbi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
            }
        ],
        name: 'Approval',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'delegator',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'fromDelegate',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'toDelegate',
                type: 'address'
            }
        ],
        name: 'DelegateChanged',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'delegate',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'previousBalance',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'newBalance',
                type: 'uint256'
            }
        ],
        name: 'DelegateVotesChanged',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            { indexed: true, internalType: 'address', name: 'from', type: 'address' },
            { indexed: true, internalType: 'address', name: 'to', type: 'address' },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
            }
        ],
        name: 'Transfer',
        type: 'event'
    },
    {
        inputs: [],
        name: 'DELEGATION_TYPEHASH',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'DOMAIN_TYPEHASH',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' }
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'uint32', name: '', type: 'uint32' }
        ],
        name: 'checkpoints',
        outputs: [
            { internalType: 'uint32', name: 'fromBlock', type: 'uint32' },
            { internalType: 'uint256', name: 'votes', type: 'uint256' }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' }
        ],
        name: 'decreaseAllowance',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'delegatee', type: 'address' }],
        name: 'delegate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'delegatee', type: 'address' },
            { internalType: 'uint256', name: 'nonce', type: 'uint256' },
            { internalType: 'uint256', name: 'expiry', type: 'uint256' },
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' }
        ],
        name: 'delegateBySig',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'delegator', type: 'address' }],
        name: 'delegates',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'getCurrentVotes',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'getOwner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'blockNumber', type: 'uint256' }
        ],
        name: 'getPriorVotes',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
        ],
        name: 'increaseAllowance',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: '_to', type: 'address' },
            { internalType: 'uint256', name: '_amount', type: 'uint256' }
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'mint',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'nonces',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'numCheckpoints',
        outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'recipient', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'sender', type: 'address' },
            { internalType: 'address', name: 'recipient', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' }
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    }
]

export const NETWORK_LIST = [
    {
        name: 'Ethereum',
        shortname: 'eth',
        logo: 'https://ipfs.gateway.token-monitor.com/ipfs/QmTho9ytAZbS8YJrMWref176cboKqxrYQRo8MAFv6CGsWK',
        nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
        chainId: ETH_MAINNET,
        rpcUrls: ['https://cloudflare-eth.com/'],
        jumpTo: 'https://v2.fatsale.finance/#/token/create?chain=eth'
    },
    {
        name: 'Arbitrum One',
        shortname: 'arb',
        logo: 'https://ipfs.gateway.token-monitor.com/ipfs/QmWfs8DpKkry9Mg4uVMw78rk39RKyeDXq4YcvMdGvEkCxA',
        nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
        chainId: ARB_MAINNET,
        rpcUrls: ['https://arb1.arbitrum.io/rpc']
    },
    {
        name: 'BNB Chain',
        shortname: 'bsc',
        logo: 'https://ipfs.gateway.token-monitor.com/ipfs/QmezTpvQm9tJwN9Vh2GtJv4udofLW3n9vzYA4uuafaBSGG',
        nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
        chainId: BSC_MAINNET,
        rpcUrls: ['https://bsc-dataseed1.binance.org/', 'https://bsc-dataseed3.defibit.io/', 'https://bsc-dataseed4.defibit.io/']
    },
    {
        name: 'OKX Chain',
        shortname: 'oec',
        logo: 'https://token-monitor.s3.amazonaws.com/okx_logo.jpeg',
        nativeCurrency: { name: 'OKT', decimals: 18, symbol: 'OKT' },
        chainId: OKX_MAINNET,
        rpcUrls: ['https://exchainrpc.okex.org'],
        isTestNetwork: false
    },
    // {
    //     name: 'Polygon',
    //     shortname: 'matic',
    //     logo: 'https://token-monitor.s3.amazonaws.com/polygon-matic-logo.png',
    //     nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
    //     chainId: POLYGON_MAINNET,
    //     rpcUrls: ['https://polygon-rpc.com/'],
    //     isTestNetwork: false
    // },
    {
        name: 'HECO',
        shortname: 'heco',
        logo: 'https://ipfs.gateway.token-monitor.com/ipfs/QmbNs2aMpGha9x1pW9bymm7UhzJJMCqYLQin25xqrmSeFQ',
        nativeCurrency: { name: 'HT', decimals: 18, symbol: 'HT' },
        chainId: HECO_MAINNET,
        rpcUrls: ['https://http-mainnet.hecochain.com']
    },
    {
        name: '山海经',
        shortname: 'shj',
        logo: 'https://ipfs.gateway.token-monitor.com/ipfs/QmRi7T2UMygkphGtEWwaueS6CdXgf1PFGKPdWhMHvDxezW',
        nativeCurrency: { name: 'SHJ', decimals: 18, symbol: 'SHJ' },
        chainId: SHJ_MAINNET,
        rpcUrls: ['https://rpc.shjscan.com'],
        isTestNetwork: false
    },
    {
        name: 'CORE Chain',
        shortname: 'core',
        logo: 'https://scan.coredao.org/images/icon.png',
        nativeCurrency: { name: 'CORE', decimals: 18, symbol: 'CORE' },
        chainId: CORE_MAINNET,
        rpcUrls: ['https://rpc.coredao.org'],
        isTestNetwork: false
    },
    {
        name: 'EthereumPow',
        shortname: 'ethw',
        logo: 'https://app.bridgetech.network/static/media/ethw.d008347d.png',
        nativeCurrency: { name: 'ETHW', decimals: 18, symbol: 'ETHW' },
        chainId: ETHW_MAINNET,
        rpcUrls: ['https://mainnet.ethereumpow.org'],
        jumpTo: 'https://v2.fatsale.finance/#/token/create?chain=ethw'
    },
    {
        name: 'TelegramX',
        shortname: 'TX',
        logo: 'https://ipfs.gateway.token-monitor.com/ipfs/QmNTeTCPjUjqhHgjghJeNa7ark1Rb4SBK2a5c1rwCsHjxb',
        nativeCurrency: { name: 'TX', decimals: 18, symbol: 'TX' },
        chainId: TX_MAINNET,
        rpcUrls: ['https://tx.telegramx.space'],
        jumpTo: 'https://v2.fatsale.finance/#/token/create?chain=TX'
    },
    // {
    //     name: 'SHIB',
    //     shortname: 'shib',
    //     logo: 'https://shibswap.shibchain.app/static/media/logo-shib.e41c6a10.png',
    //     nativeCurrency: { name: 'WSHIB', decimals: 18, symbol: 'WSHIB' },
    //     chainId: SHIB_MAINET,
    //     rpcUrls: ['https://rpc.shibchain.app/']
    // },
    {
        name: 'FON',
        shortname: 'fon',
        logo: 'https://rosswap.com/static/media/bnb.47ab5796.png',
        nativeCurrency: { name: 'WFON', decimals: 18, symbol: 'WFON' },
        chainId: FSC_MAINNET,
        rpcUrls: ['https://fsc-dataseed2.fonscan.io'],
        jumpTo: 'https://v2.fatsale.finance/#/token/create?chain=fon'
    },

    {
        name: 'BNB Chain',
        shortname: 'bsc-test',
        logo: 'https://ipfs.gateway.token-monitor.com/ipfs/QmezTpvQm9tJwN9Vh2GtJv4udofLW3n9vzYA4uuafaBSGG',
        nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
        chainId: BSC_TESTNET,
        rpcUrls: ['https://rpc.ankr.com/bsc_testnet_chapel'],
        isTestNetwork: true
    },
    {
        name: 'Conflux eSpace',
        shortname: 'cfx',
        logo: 'https://developer.confluxnetwork.org/img/logo.svg',
        nativeCurrency: { name: 'CFX', decimals: 18, symbol: 'CFX' },
        chainId: CFX_MAINNET,
        rpcUrls: ['https://evm.confluxrpc.com']
    }
    // {
    //     name: 'DOJO',
    //     shortname: 'dojo',
    //     logo: 'https://app.dojochain.co/static/media/logo.f21d2e98102bf26a1f7d.png',
    //     nativeCurrency: { name: 'sDOJO', decimals: 18, symbol: 'sDOJO' },
    //     chainId: DOJO_MAINNET,
    //     rpcUrls: ['https://http-mainnet.dojochain.co'],
    //     isTestNetwork: false
    // }

]




export const getChainIdFromShortName = (shortName) => {
    const chain = NETWORK_LIST.find((chain) => chain.shortname === shortName)
    return chain?.chainId || 0

}

export const getProviderWithChainId = (chainId) => {
    let provider = null
    const networks = NETWORK_LIST.filter(item => {
        return item.chainId === parseInt(chainId)
    })
    if (networks && networks.length > 0) {
        provider = new ethers.providers.JsonRpcProvider(networks[0].rpcUrls[0])
    }
    return provider
}

export const getChainSymbol = (chainId) => {
    const chain = NETWORK_LIST.find((chain) => chain.chainId === chainId)
    return chain?.nativeCurrency.name || ''

}

export const initProvider = async (provider, chainId) => {
    await provider.init()
    if (chainId === SHIB_MAINET) {
        provider._multicallAddress = '0xE0bD6E231a19CC546716B96749d5Bc3D68235Ffa'
    }
    if (chainId === ETHW_MAINNET) {
        provider._multicallAddress = '0x31344338c564439Adae414be5E148Ca8a91A981C'
    }
    if (chainId === FSC_MAINNET) {
        provider._multicallAddress = '0xB890F6F1b84F48D22d75A7ceBEDb13B768763817'
    }
    if (chainId === TX_MAINNET) {
        provider._multicallAddress = '0x77E72966494625bCC2af79E73F870D8eA5f8700C'
    }
    if (chainId === DOJO_MAINNET) {
        provider._multicallAddress = '0xb34f4eFe2E2c4532DEF1842DBa66B02091ed247c'
    }
    if (chainId === OKX_MAINNET) {
        provider._multicallAddress = '0xb18FF6Fa18AF8361D784D377Fe1e929fCb72B8A3'
    }
    if (chainId === SHJ_MAINNET) {
        provider._multicallAddress = '0x80dBd7fDeF99b6Fa79A2e9eE5C569bcdf105FbD6'
    }
    if (chainId === CORE_MAINNET) {
        provider._multicallAddress = '0x42fCE0f1f0a291b44e72d7A8f04f41228F84304A'
    }
    if (chainId === ARB_MAINNET) {
        provider._multicallAddress = '0x219dDfEFC34faaED9CB977098162f52d20E8de79'
    }
    if (chainId === CFX_MAINNET) {
        provider._multicallAddress = '0x9f3a790D6332A1820C214c97927a2A164EA7605B'
    }
    return provider
}

export const zeroAddress = '0x0000000000000000000000000000000000000000'