import React, { Component } from 'react';
import { BigNumber, ethers } from 'ethers';
import { getChainIdFromShortName, getProviderWithChainId, initProvider, MiningPoolAbi, tokenAbi, zeroAddress } from '../../utils/config';
import { Contract, Provider } from 'ethers-multicall';
import moment from 'moment-timezone';
import { eventBus } from '../../utils/tool';
import { getIpfsText } from '../../utils/ipfs';
import { connect } from "react-redux"
import store from '../../store';
import SweetAlert2 from 'react-sweetalert2';
import { withTranslation } from 'react-i18next';

import copy from 'copy-to-clipboard';


// import '@sweetalert2/theme-dark/dark.scss';
import "./swal-dark.css"

import StakeModal from './components/StakeModal/index'
import UnstakeModal from './components/UnstakeModal/index'
import IpfsPanel from './components/IpfsPanel/index'
import InviteConfirmModal from './components/InviteConfirmModal/index'
import WhitelistModal from './components/WhitelistModal/index'
import UserListTable from './components/UserListTable/index'

moment.tz.setDefault('UTC') // global style

class Staking0421 extends Component {
    state = {
        data: {},
        tabData: [],
        tabContent: [],
        features: [],
        chain: '', // 公链
        contract: '', // 矿池地址
        account: '',
        ipfsInfo: {},
        stakeTokenInfo: {},
        rewardTokenInfo: {},
        poolInfo: {},
        showStakeModal: false,
        stakeAmountInput: 0, // 质押数量
        stakeMax: false, // 点了max按钮
        swalProps: {},
        reawardLoading: false,// 领取奖励的loading
        currentProvider: null,
        showUnstakeModal: false, // 展示解除质押按钮
        stopRewardLoading: false, // 停止质押的loading
        enableForceWithdrawLoading: false, // 强制解除质押的loading
        showTimeDetail: false, // 是否展示时间详情
        showIpfsPanel: false, // 展示ipfs信息
        countdown: {
            type: 0,
            ct: '00:00:00' // 时 分 秒
        },
        showInviteConfirmModal: false, // 展示确认邀请modal
        showWhitelistModal: false, // 设置白名单
        params: {},
        urlPath: '', // 当前的路径 主机+域名+#+hash
        invitorList: [], // 当前用户的邀请人列表信息
        invitorTotalCount: 0,
        perPage: 10,
        isAdmin: false, // 是否是管理员
        stakeUserList: [], // 质押用户列表
        adminInfo: {}, // 管理员信息,
        networkName: '',
        notShowList: [

        ], // 不显示交易对的列表
    }

    async initProvider() {
        let provider = null
        const chainId = this.state.chainId
        console.log('chain_idddd', chainId)
        if (window.ethereum) {
            const hexChainId = await window.ethereum.request({ method: 'eth_chainId' })
            const cid = parseInt(BigNumber.from(hexChainId).toString())
            // 如果chainId能对上再继续
            if (chainId === cid) {
                // 直接走获取provider的逻辑
                provider = new ethers.providers.Web3Provider(window.ethereum)
            }
        }
        if (!provider) {
            // 直接走获取provider的逻辑
            provider = getProviderWithChainId(chainId)
        }

        return provider

        // if (this.state.currentProvider) {
        //     return this.state.currentProvider
        // }
        // if (window.ethereum && !this.state.currentProvider) {
        //     const provider = new ethers.providers.Web3Provider(window.ethereum)
        //     this.setState({
        //         currentProvider: provider
        //     })
        //     return provider
        // } else {
        //     console.log('No provider')
        // }
    }

    componentDidMount() {

        this.setState({
            swalProps: {
                show: false,
                title: '',
                text: '',
            }
        })
        eventBus.addListener("walletConnect", this.walletConnect.bind(this))
        // 获取链和合约
        // 根据合约获取ipfs数据, 更新信息
        this.fetchData()

        // axios.get(`${BASE_URL}`)
        //     .then(res => {
        //         this.setState({
        //             data: res.data,
        //             tabData: res.data.tabData,
        //             tabContent: res.data.tabContent,
        //             features: res.data.features
        //         })
        //         // console.log(this.state.data)
        //     })
        //     .catch(err => console.log(err))

        eventBus.addListener('forceWithdrawSuccess', this.handleForceWithdrawSuccess.bind(this))
        eventBus.addListener('withdrawSuccess', this.handleWithDrawSuccess.bind(this))

    }
    handleWithDrawSuccess() {
        this.fetchData()
        this.showMessageModal({
            show: true,
            title: this.props.t('成功'),
            text: this.props.t('解除质押成功'),
            icon: 'success'
        })
    }

    handleForceWithdrawSuccess() {
        this.fetchData()
        this.showMessageModal({
            show: true,
            title: this.props.t('成功'),
            text: this.props.t('紧急撤离成功'),
            icon: 'success'
        })
    }



    async getTokenInfo(tokenAddress, provider, chainId, currentWallet, miningContract) {
        let ethcallProvider = new Provider(provider)
        // await ethcallProvider.init()
        ethcallProvider = await initProvider(ethcallProvider, chainId)

        const token = new Contract(tokenAddress, tokenAbi)

        const call = [token.name(), token.symbol(), token.decimals(), token.totalSupply()]
        if (currentWallet) {
            call.push(token.allowance(currentWallet, miningContract))
            call.push(token.balanceOf(currentWallet))
        }
        const res = await ethcallProvider.all(call)
        const decimals = parseInt(res[2].toString())
        const d = Math.pow(10, decimals)

        let allowance = 0
        let balance = 0
        let balanceBigNumber = null
        if (currentWallet) {
            allowance = res[4].toString()
            balance = res[5].toString()
            balanceBigNumber = res[5] // 是一个bigNumber
        }

        const fatTokenAbi = [
            'function token0() external view returns(address)',
            'function token1() external view returns(address)',
        ]
        let isPairToken = false
        let token0Info = {}
        let token1Info = {}
        try {
            const pairToken = new Contract(tokenAddress, fatTokenAbi)
            const call = [pairToken.token0(), pairToken.token1()]
            const res2 = await ethcallProvider.all(call)
            isPairToken = true
            token0Info = await this.getTokenInfo(res2[0], provider, chainId, currentWallet, miningContract)
            token1Info = await this.getTokenInfo(res2[1], provider, chainId, currentWallet, miningContract)
        } catch (e) {

        }

        return {
            name: res[0],
            symbol: res[1],
            decimals,
            totalSupply: res[3].toString() / d,
            address: tokenAddress,
            allowance,
            balance,
            balanceBigNumber,
            isPairToken,
            token0Info,
            token1Info
        }
    }
    componentWillUnmount() {
        eventBus.removeListener("walletConnect", this.walletConnect.bind(this))
        eventBus.removeListener('forceWithdrawSuccess', this.handleForceWithdrawSuccess.bind(this))
        eventBus.removeListener('withdrawSuccess', this.handleWithDrawSuccess.bind(this))
        this.countdownTimer && clearInterval(this.countdownTimer)
    }



    walletConnect(v) {
        if (v.account) {
            // 执行初始化操作
            this.setState({
                account: v.account
            }, () => {
                console.log('钱包已连接', this.state.account)
                this.fetchData()
            })
        }
    }
    async fetchData() {
        // 获取url参数
        const query = new URL(window.location.href)
        const r = query.hash.replace('#/', '').split('/')
        let ps = []
        if (r.length > 1) {
            ps = r[1].split('?')
        }

        let contract = ''
        if (ps.length > 0) {
            contract = ps[0]
        }

        const hrefArr = (query.origin + '/' + query.hash).split('?')

        console.log('psssss', ps)

        if (ps.length > 0) {
            const params = new URLSearchParams(ps[1])
            // 获取inviter
            const m = {}
            for (const [key, value] of params.entries()) {
                m[key] = value || ''
            }
            this.setState({
                params: m
            })
        }
        const pattern = /(0x[a-fA-F0-9]{40})/g;
        const matches = window.location.href.match(pattern);
        if (matches) {
            const address = matches[0];
            this.setState({
                params: {
                    ...this.state.params,
                    invitor: address
                }
            })
        }

        // 获取代币信息
        // 如果当前钱包和选择的公链一致, 则使用当前的provider
        // 如果不一致, 则自动选择provider


        let tmpChain = r[0]
        tmpChain = 'bsc'
        let tmpContract = '0x19E9E52713b66Ab0Ba94eF992742Ac7A625Cbf64'
        const chainId = getChainIdFromShortName(tmpChain)
        contract = tmpContract

        this.setState({
            chain: tmpChain,
            contract,
            urlPath: hrefArr[0],
            showStakeModal: false,
            showUnstakeModal: false,
            chainId
        }, async () => {

            const provider = await this.initProvider()

            // 获取矿池信息
            let ethcallProvider = new Provider(provider)
            ethcallProvider = await initProvider(ethcallProvider, chainId)

            const pool = new Contract(contract, MiningPoolAbi)

            // 开始时间 结束时间 最大质押量 锁仓时间 apr 我的质押量 我的收益
            const calls1 = [
                pool.stakedToken(),
                pool.rewardToken(),
                pool.startBlock(),
                pool.bonusEndBlock(),
                pool.poolLimitPerUser(),
                pool.numberBlocksForUserLimit(),
                pool.lengthOflockTime(),
                pool.owner(),
                pool.enableInviter(),
                pool._baseURI(),
                pool.usercanemergencyWithdraw(), // 是否允许紧急提现
                pool.lenOfInvitorRewardPercentList(), // 邀请人奖励比例列表长度
                pool.feeOfOpenInviter(), // 开启邀请人功能的费用
            ]


            if (this.state.account) {
                //  我的收益 我的质押量 当前能否提现 我的解锁时间
                calls1.push(pool.pendingReward(this.state.account))
                calls1.push(pool.userInfo(this.state.account))
                calls1.push(pool.getUserCanWithdraw(this.state.account))
                calls1.push(pool.getUnlockTime(this.state.account))
                calls1.push(pool._invitor(this.state.account))
            }

            const res1 = await ethcallProvider.all(calls1)

            const miningInfo = {
                stakeToken: res1[0],
                rewardToken: res1[1],
                startTime: moment(parseInt(res1[2].toString()) * 1000),
                endTime: moment(res1[3].toString() * 1000),
                poolLimitPerUser: parseInt(res1[4].toString()),
                numberBlocksForUserLimit: parseInt(res1[5].toString()),
                deadline: moment((parseInt(res1[2].toString()) + parseInt(res1[5].toString())) * 1000),
                lockTime: parseInt(res1[6].toString()),
                owner: res1[7],
                enableInvitor: res1[8], // 是否有邀请人
                ipfsKey: res1[9],
                allowMergencyWithdraw: res1[10],
                invitorRewardPercentListLength: parseInt(res1[11].toString()),
                feeOfOpenInviter: parseInt(res1[12].toString()) / 1e18,
                pendingReward: res1[13] ? parseInt(res1[13].toString()) : 0,
                userStakedAmount: res1[14] ? parseInt(res1[14].amount.toString()) : 0,
                userStakeAmountBigNumber: res1[14] ? res1[14].amount : null, // 质押量的bigNumber
                userExtendPownerAmount: res1[14] ? parseInt(res1[14].extendPower.toString()) : 0,
                userCanWithdraw: res1[15] || false,
                unlockTime: res1[16] ? parseInt(res1[16].toString()) : 0,
                myInvitor: (res1[17] && res1[17] !== zeroAddress) ? res1[17] : ''
            }

            //  获取邀请比例列表
            if (miningInfo.invitorRewardPercentListLength > 0) {
                const calls2 = []
                for (let i = 0; i < miningInfo.invitorRewardPercentListLength; i++) {
                    calls2.push(pool.invitorRewardPercentList(i))
                }
                const res2 = await ethcallProvider.all(calls2)
                const iList = []
                res2.forEach((item, index) => {
                    iList.push(item.toString() / 100)
                })
                miningInfo.invitorRewardPercentList = iList
            } else {
                miningInfo.invitorRewardPercentList = []
            }


            // 如果开启了邀请功能, 则获取下级列表
            if (miningInfo.enableInvitor) {
                this.getInvitorList(1)
            }

            // this.state.poolInfo.owner.toLowerCase() === this.state.account.toLowerCase()
            const isAdmin = miningInfo.owner?.toLowerCase() === this.state.account?.toLowerCase()

            if (isAdmin) {
                // 获取管理员相关信息
                this.getStakeListForAdmin(1)
            }

            this.setState({
                poolInfo: miningInfo,
                isAdmin,
                chainId,
                networkName: r[0]
            }, () => {
                // 开启定时器
                this.countdownTimer && clearInterval(this.countdownTimer)
                this.countdownTimer = setInterval(() => {
                    // 计算倒计时信息
                    this.calcCountdownInfo()
                }, 1000)

                // 如果是项目方, 则获取管理员面板信息
            })

            // 获取ipfs信息
            if (miningInfo.ipfsKey) {
                getIpfsText(miningInfo.ipfsKey)
                    .then(res => {
                        console.log('ipfs信息', JSON.parse(res))
                        this.setState({
                            ipfsInfo: JSON.parse(res)
                        })
                        store.dispatch({
                            type: 'setMiningInfo',
                            value: JSON.parse(res)
                        })
                    })
            }

            // 获取质押代币信息
            const stakeTokenInfo = await this.getTokenInfo(miningInfo.stakeToken, provider, chainId, this.state.account || '', contract)
            // 获取奖励代币信息
            const rewardTokenInfo = await this.getTokenInfo(miningInfo.rewardToken, provider, chainId, this.state.account || '', contract)


            console.log('质押代币信息', stakeTokenInfo)
            // 设置到state中
            this.setState({
                stakeTokenInfo,
                rewardTokenInfo
            })

        })



    }


    checkNetwork() {
        if (this.state.chainId !== this.props.chainId) {
            this.showMessageModal({
                show: true,
                title: this.props.t('错误'),
                text: this.props.t('当前网络不匹配, 请连接CORE钱包').replace('{net}', this.state.networkName.toUpperCase()),
            })
            return false
        }
        return true
    }

    handleErr(e) {
        let t = ''
        if (e.reason) {
            t = e.reason
        } else {
            t = JSON.stringify(e)
        }
        if (t.indexOf('not reach') > -1) {
            t = this.props.t('未到达解锁时间')
        }
        this.showMessageModal({
            show: true,
            title: this.props.t('错误'),
            text: t,
        })
    }

    async calcCountdownInfo() {
        const now = moment()
        const { startTime, endTime, unlockTime } = this.state.poolInfo

        this.setState({
            poolInfo: {
                ...this.state.poolInfo,
                unlockTime: (unlockTime - 1 > 0) ? unlockTime - 1 : 0
            }
        })
        let type = 1 // 1:开始倒计时 2:结束倒计时 3: 已结束
        // 倒计时
        let countdown = 0
        if (now.isBefore(startTime)) {
            countdown = startTime.diff(now, 'seconds')
        } else if (now.isBefore(endTime) && now.isAfter(startTime)) {
            type = 2
            countdown = endTime.diff(now, 'seconds')
        } else if (now.isAfter(endTime)) {
            type = 3
        }

        // 倒计时文案
        let days = Math.floor(countdown / 86400)
        let hours = Math.floor((countdown - days * 86400) / 3600)
        let minutes = Math.floor((countdown - days * 86400 - hours * 3600) / 60)
        let seconds = Math.floor(countdown - days * 86400 - hours * 3600 - minutes * 60)
        days = days < 10 ? '0' + days : days
        hours = hours < 10 ? '0' + hours : hours
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        const countdownText = `${days}:${hours}:${minutes}:${seconds}`

        this.setState({
            countdown: {
                type,
                ct: countdownText,
            }
        })

    }

    async getStakeListForAdmin(page) {
        // 获取质押总量 当前质押总人数 当前质押总算力
        if (!this.state.account) {
            return
        }
        // 获取下级列表
        const p = await this.initProvider()
        let ethcallProvider = new Provider(p)
        ethcallProvider = await initProvider(ethcallProvider, this.state.chainId)
        const pool = new Contract(this.state.contract, MiningPoolAbi)

        const calls1 = [pool.getUsersCount(), pool.getTotalPower(), pool.getTotalStakeAmount()]
        const res1 = await ethcallProvider.all(calls1)
        console.log('admin111', res1)
        const adminInfo = {
            userCount: parseInt(res1[0].toString()), // 质押总人数
            totalPower: res1[1].toString(), // 质押总算力
            totalStakeAmount: res1[2].toString(), // 质押总量
        }

        // 获取质押列表
        const perPage = this.state.perPage

        const calls2 = []
        for (let i = adminInfo.userCount - ((page - 1) * perPage) - 1; i >= 0; i--) {
            calls2.push(pool.getUserById(ethers.utils.parseUnits(i + '', 0)))
            if (calls2.length >= perPage) break
        }
        const list = await ethcallProvider.all(calls2)

        const arr = list.map(item => {
            // 已提升算力 已领取 质押数 奖励数 用户地址 邀请人
            return {
                invitor: item.invitor,
                userAddr: item.userAddr,
                stakedTokenAmount: item.stakeAmount.toString(),
                rewardTokenAmount: item.rewardAmount.toString(),
                claimedTokenAmount: item.hasBeenClaimdReward.toString(),
                extendPower: item.extendPower.toString(),
            }
        })
        console.log('第', page, '页信息', list, arr)

        this.setState({
            adminInfo,
            stakeUserList: arr,
        })

    }

    async getInvitorList(page) {
        if (!this.state.account) {
            return
        }
        // 获取下级列表
        const p = await this.initProvider()
        let ethcallProvider = new Provider(p)
        ethcallProvider = await initProvider(ethcallProvider, this.state.chainId)

        // 获取下级总数, 用来分页
        // 按照倒序, 获取下级地址
        // 根据下级地址, 获取信息
        const pool = new Contract(this.state.contract, MiningPoolAbi)

        // 获取总数
        // 切片 获取信息
        const calls1 = [pool.inviterListsForUserTotal(this.state.account)]
        const res1 = await ethcallProvider.all(calls1)
        console.log('总数为', res1[0].toString())
        const total = res1[0].toString()
        // 分页
        const perPage = this.state.perPage

        const calls2 = []
        for (let i = total - ((page - 1) * perPage) - 1; i >= 0; i--) {
            calls2.push(pool.inviterListsForUserPage(this.state.account, ethers.utils.parseUnits(i + '', 0)))
            if (calls2.length >= perPage) break
        }
        const list = await ethcallProvider.all(calls2)

        const arr = list.map(item => {
            // 已提升算力 已领取 质押数 奖励数 用户地址 邀请人
            return {
                invitor: item.invitor,
                userAddr: item.userAddr,
                stakedTokenAmount: item.stakeAmount.toString(),
                rewardTokenAmount: item.rewardAmount.toString(),
                claimedTokenAmount: item.hasBeenClaimdReward.toString(),
                extendPower: item.extendPower.toString(),
            }
        })
        console.log('第', page, '页信息', list, arr)

        this.setState({
            invitorList: arr,
            invitorTotalCount: parseInt(total),
            perPage
        })




    }

    async enableForceWithdraw() {
        if (this.checkNetwork() === false) return
        // 允许紧急撤离矿池
        const r = this.state.poolInfo.allowMergencyWithdraw ? false : true
        this.setState({
            enableForceWithdrawLoading: true
        })
        const p = await this.initProvider()
        const a = new ethers.Contract(this.state.contract, MiningPoolAbi, p.getSigner())
        try {
            const tx = await a.setUsercanemergencyWithdraw(r)
            const receipt = await tx.wait()
            this.setState({
                swalProps: {
                    show: true,
                    title: this.props.t('成功'),
                    text: this.props.t('设置成功'),
                }
            })
            this.fetchData()
        } catch (e) {
            this.handleErr(e)
        }
        this.setState({
            enableForceWithdrawLoading: false
        })
    }

    async withdrawRemindRewardTokens() {
        // 提取剩余奖励代币
        if (this.checkNetwork() === false) return
        // 获取奖励代币余额, 然后提取代币
        const p = await this.initProvider()
        this.setState({
            stopRewardLoading: true
        })
        try {
            const token = new ethers.Contract(this.state.rewardTokenInfo.address, tokenAbi, p.getSigner())
            const balance = await token.balanceOf(this.state.contract)
            console.log('余额为', balance.toString() / Math.pow(10, this.state.rewardTokenInfo.decimals))
            const balanceString = balance.toString() / Math.pow(10, this.state.rewardTokenInfo.decimals)
            if (parseFloat(balanceString) === 0) {
                this.showMessageModal({
                    show: true,
                    title: this.props.t('错误'),
                    text: this.props.t('矿池内奖励代币余额为0'),
                })
                this.setState({
                    stopRewardLoading: false
                })
                return
            }
            const a = new ethers.Contract(this.state.contract, MiningPoolAbi, p.getSigner())
            const tx = await a.emergencyRewardWithdraw(balance)
            const receipt = await tx.wait()
            this.showMessageModal({
                show: true,
                title: this.props.t('成功'),
                text: this.props.t('已成功提取x枚奖励代币').replace('{amount}', balanceString).replace('{token}', this.state.rewardTokenInfo.symbol),
            })
        } catch (e) {

        }

        this.setState({
            stopRewardLoading: false
        })
    }

    async stopRewardLogic() {
        console.log('结束矿池')
        if (this.checkNetwork() === false) return
        this.setState({
            stopRewardLoading: true
        })

        const p = await this.initProvider()
        const a = new ethers.Contract(this.state.contract, MiningPoolAbi, p.getSigner())
        try {
            const tx = await a.stopReward()
            const receipt = await tx.wait()
            this.setState({
                swalProps: {
                    show: true,
                    title: this.props.t('成功'),
                    text: this.props.t('当前矿池已强制停止'),
                }
            })
        } catch (e) {
            this.handleErr(e)
        }

        this.setState({
            stopRewardLoading: false
        })
    }

    showMessageModal(info) {
        this.setState({
            swalProps: {
                show: false,
                title: '',
                text: '',
            }
        }, () => {
            this.setState({
                swalProps: {
                    ...info
                }
            })
        })

    }



    getLockTimeString(time) {
        const t = parseInt(time)
        const day = Math.floor(t / 86400)
        const hour = Math.floor((t - day * 86400) / 3600)
        const minute = Math.floor((t - day * 86400 - hour * 3600) / 60)
        const second = t - day * 86400 - hour * 3600 - minute * 60
        return `${day > 0 ? (day + this.props.t('天')) : ''} ${hour > 0 ? (hour + this.props.t('小时')) : ''} ${minute > 0 ? (minute + this.props.t('分钟')) : ''} ${second > 0 ? (second + this.props.t('秒')) : ''}`
    }

    generateShareLink() {
        // http://localhost:3000/#/core/0x928D87B7c6Ea72a44E86d72990d199C9905202f9?invitor=0x928D87B7c6Ea72a44E86d72990d199C9905202f9
        return `${this.state.urlPath}?invitor=${this.props.account}`
    }

    render() {

        document.title = this.props.t('质押挖矿')
        return (
            <section className="staking-area">
                <SweetAlert2 {...this.state.swalProps} />
                <WhitelistModal
                    poolAddress={this.state.contract}
                    show={this.state.showWhitelistModal}
                    handleClose={() => {
                        this.setState({
                            showWhitelistModal: false
                        })
                    }}
                    ok={() => {
                        this.fetchData()
                        this.setState({
                            showWhitelistModal: false
                        })
                        this.showMessageModal({
                            show: true,
                            title: this.props.t('成功'),
                            text: this.props.t('设置成功'),
                            icon: 'success'
                        })
                    }}
                />
                <InviteConfirmModal
                    invitorRewardPercentList={this.state.poolInfo?.invitorRewardPercentList}
                    fee={this.state.poolInfo.feeOfOpenInviter}
                    poolAddress={this.state.contract}
                    show={this.state.showInviteConfirmModal}
                    open={this.state.poolInfo.enableInvitor}
                    handleClose={() => {
                        this.setState({
                            showInviteConfirmModal: false
                        })
                    }}
                    ok={() => {
                        this.fetchData()
                        this.setState({
                            showInviteConfirmModal: false
                        })
                        this.showMessageModal({
                            show: true,
                            title: this.props.t('成功'),
                            text: this.props.t('设置成功'),
                            icon: 'success'
                        })
                    }}
                />
                <IpfsPanel onRef={node => this.ipfsPanelRef = node} handleClose={() => {
                    this.setState({
                        showIpfsPanel: false
                    })
                }} info={this.state.ipfsInfo} contract={this.state.contract} show={this.state.showIpfsPanel} ok={(p) => {
                    // 调用合约
                    this.fetchData()
                    this.setState({
                        showIpfsPanel: false
                    })
                }} />

                <UnstakeModal onRef={node => this.unStakeModalRef = node} handleClose={() => {
                    this.setState({
                        showUnstakeModal: false
                    })
                }} show={this.state.showUnstakeModal} />
                <StakeModal
                    invitor={this.state.params['invitor'] || ''}
                    onRef={node => this.stakeModalRef = node} handleClose={() => {
                        this.setState({
                            showStakeModal: false
                        })
                    }} ok={() => {
                        // 质押完成
                        this.setState({
                            stakeAmountInput: ''
                        })
                        this.fetchData()
                    }} show={this.state.showStakeModal} />
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <div className="card no-hover staking-card single-staking">
                                <div className="stake-logo-box">
                                    <div className='stake-logo-img stake-logo-img-left'>
                                        <img className='stake-logo-img-im' src={this.state.ipfsInfo.stakedTokenLogo} />
                                        <div className='stake-logo-img-im-small-box'>
                                            <img className='stake-logo-img-im-small' src={this.state.ipfsInfo.rewardTokenLogo} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="m-0">{this.props.t('质押title')}
                                            {this.state.stakeTokenInfo.isPairToken ? `${this.state.stakeTokenInfo?.token0Info?.symbol}/${this.state.stakeTokenInfo?.token1Info?.symbol}` : ''} {this.state.stakeTokenInfo.symbol}</h3>
                                        <span className="balance">{this.props.t('赚取')} {this.state.rewardTokenInfo.symbol}</span>
                                    </div>
                                    <div className='stake-logo-img stake-logo-img-right'>
                                        <img className='stake-logo-img-im' src={this.state.ipfsInfo.stakedTokenLogo} />
                                        <div className='stake-logo-img-im-small-box'>
                                            <img className='stake-logo-img-im-small' src={this.state.ipfsInfo.rewardTokenLogo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content mt-md-3" id="myTabContent">
                                    <div role="tabpanel">
                                        <div className="staking-tab-content">
                                            {/* Info Box */}
                                            <div className="info-box d-flex justify-content-between">
                                                <div className="info-left">
                                                    <ul className="list-unstyled">
                                                        {this.state?.poolInfo?.lockTime > 0 && <li><strong>
                                                            {this.props.t('锁仓时间')}: </strong> {this.getLockTimeString(this.state.poolInfo.lockTime)}</li>}
                                                        {parseFloat(this.state.poolInfo.poolLimitPerUser) > 0 && <li><strong>{this.props.t('最大质押量')}:</strong> {(this.state.poolInfo.poolLimitPerUser / Math.pow(10, this.state.stakeTokenInfo.decimals)).toFixed(0)} {this.state.stakeTokenInfo.symbol}</li>}
                                                        <li><strong>{this.props.t('我的质押量')}:</strong>  {this.state.poolInfo.userStakedAmount / Math.pow(10, this.state.stakeTokenInfo.decimals)} {this.state.stakeTokenInfo.symbol}
                                                            {this.state.stakeTokenInfo?.decimals > 0 && this.state.poolInfo?.userExtendPownerAmount > 0 && <span className='extend-stake-amount'> &nbsp;+{(this.state.poolInfo.userExtendPownerAmount / Math.pow(10, this.state.stakeTokenInfo.decimals)).toFixed(2)}</span>}
                                                        </li>
                                                        {
                                                            // 已经质押过, 并且设置了锁仓时间
                                                            this.state.poolInfo.lockTime > 0 && this.state.poolInfo.userStakedAmount > 0 && <li><strong>{this.props.t('质押解锁时间')}:</strong>  {this.getLockTimeString(this.state.poolInfo.unlockTime)} </li>
                                                        }
                                                        <li><strong>{this.props.t('我的收益')}:</strong>  {this.state.poolInfo.pendingReward / Math.pow(10, this.state.rewardTokenInfo.decimals)} {this.state.rewardTokenInfo.symbol}</li>
                                                    </ul>
                                                </div>
                                                <div className="info-right d-flex flex-column">
                                                    {/* <span >{this.state.poolInfo.apr}%</span> */}
                                                    {/* <span>APY*</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className="input-box my-4">
                                    <div className="input-area d-flex flex-column flex-md-row mb-3">
                                        <div className="input-text">
                                            <input value={this.state.stakeAmountInput} onChange={(e) => {
                                                this.setState({
                                                    stakeAmountInput: e.target.value,
                                                    stakeMax: false,
                                                })
                                            }} type="text" placeholder={0.00} />
                                            {/* <a href="#">Max</a> */}
                                            <div className="unstake-input-a">Max</div>
                                            <div onClick={() => {
                                                // 设置为最大
                                                // 如果设置了最大质押量, 则当前最大值是最大质押量
                                                const balance = this.state.stakeTokenInfo.balance / Math.pow(10, parseInt(this.state.stakeTokenInfo.decimals))
                                                if (parseFloat(this.state.poolInfo.poolLimitPerUser) > 0) {
                                                    if (balance > parseFloat(this.state.poolInfo.poolLimitPerUser) / Math.pow(10, parseInt(this.state.stakeTokenInfo.decimals))) {
                                                        // 设置为最大质押量
                                                        this.setState({
                                                            stakeAmountInput: parseFloat(this.state.poolInfo.poolLimitPerUser) / Math.pow(10, parseInt(this.state.stakeTokenInfo.decimals)) + ''
                                                        })
                                                    } else {
                                                        // 设置为余额
                                                        this.setState({
                                                            stakeAmountInput: balance,
                                                            stakeMax: true, // 质押最大值, 按照最大余额量进行质押
                                                        })
                                                    }

                                                } else {
                                                    this.setState({
                                                        stakeAmountInput: balance
                                                    })
                                                }
                                            }} className="unstake-input-a unstake-input-a-mask">
                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            console.log(this.state.params)

                                            if (this.checkNetwork() === false) {
                                                return
                                            }

                                            if (!parseFloat(this.state.stakeAmountInput)) {
                                                // 提示错误信息, 然后
                                                this.showMessageModal({
                                                    show: true,
                                                    title: this.props.t('错误'),
                                                    text: this.props.t('请输入正确的质押数量'),
                                                })
                                                return
                                            }

                                            const balance = this.state.stakeTokenInfo.balance / Math.pow(10, parseInt(this.state.stakeTokenInfo.decimals))
                                            if (parseFloat(this.state.stakeAmountInput) > parseFloat(balance)) {
                                                this.showMessageModal({
                                                    show: true,
                                                    title: this.props.t('错误'),
                                                    text: this.props.t('余额不足'),
                                                })
                                                return
                                            }

                                            // // 是否到达质押截止日期
                                            // if (this.state.poolInfo.deadline.isBefore(moment())) {
                                            //     this.showMessageModal({
                                            //         show: true,
                                            //         title: this.props.t('错误'),
                                            //         text: this.props.t('质押已经结束'),
                                            //     })
                                            //     return
                                            // }

                                            // 质押数量是否达到最大
                                            if (parseFloat((this.state.poolInfo.poolLimitPerUser)) > 0 && (this.state.poolInfo.poolLimitPerUser) / Math.pow(10, parseInt(this.state.stakeTokenInfo.decimals)) < (parseInt(this.state.poolInfo.userStakedAmount) / Math.pow(10, this.state.stakeTokenInfo.decimals) + parseFloat(this.state.stakeAmountInput))) {
                                                this.showMessageModal({
                                                    show: true,
                                                    title: this.props.t('错误'),
                                                    text: this.props.t('质押数量已经达到最大'),
                                                })
                                                return
                                            }

                                            // 展示质押信息
                                            this.setState({
                                                showStakeModal: true
                                            })

                                            // 设置授权数量
                                            // 调用其中的授权按钮
                                            // 设置数量
                                            this.stakeModalRef.setApproveInfo({
                                                amount: this.state.stakeAmountInput,
                                                tokenDecimals: parseInt(this.state.stakeTokenInfo.decimals),
                                                stakeContract: this.state.contract,
                                                tokenAddress: this.state.stakeTokenInfo.address,
                                                currentWallet: this.state.account,
                                                stakeMax: this.state.stakeMax,
                                                amountBigNumber: this.state.stakeMax ? this.state.stakeTokenInfo.balanceBigNumber : ethers.utils.parseUnits(this.state.stakeAmountInput + '', parseInt(this.state.stakeTokenInfo.decimals)),
                                                stakeTokenBalanceBigNumber: this.state.stakeTokenInfo.balanceBigNumber,
                                            }).then(() => {
                                                // 调用流程, 先授权再质押, 中间有错误就停下来
                                                this.stakeModalRef.handleLogic() // 设置数量
                                            })

                                        }} className="btn input-btn mt-2 mt-md-0 ml-md-3 approve-btn">{this.props.t('质押')}</div>
                                    </div>
                                    <div style={{ 'width': '100%' }} className="input-area d-flex flex-column flex-md-row">
                                        {/* <div className="input-text">
                                            <input type="text" placeholder={0.00} />
                                            <a href="#">Max</a>
                                        </div> */}
                                        {/* <div className='withdraw-btn'> */}
                                        <div onClick={async () => {
                                            if (this.checkNetwork() === false) {
                                                return
                                            }
                                            // 点击领取奖励
                                            // 当前奖励数量是否大于0
                                            if (this.state.poolInfo.pendingReward / Math.pow(10, this.state.rewardTokenInfo.decimals) <= 0) {
                                                this.showMessageModal({
                                                    show: true,
                                                    title: this.props.t('错误'),
                                                    text: this.props.t('暂无可领取奖励'),
                                                })
                                                return
                                            }


                                            this.setState({
                                                reawardLoading: true
                                            })
                                            // 调用接口, 获取奖励
                                            const p = await this.initProvider()
                                            const a = new ethers.Contract(this.state.contract, MiningPoolAbi, p.getSigner())
                                            try {
                                                const tx = await a.withdraw(ethers.utils.parseUnits('0', 0))
                                                const receipt = await tx.wait()
                                                this.setState({
                                                    swalProps: {
                                                        show: true,
                                                        title: this.props.t('恭喜'),
                                                        text: this.props.t('领取奖励成功'),
                                                        icon: 'success'
                                                    }
                                                })
                                            } catch (e) {
                                                this.handleErr(e)
                                            }


                                            this.setState({
                                                reawardLoading: false
                                            })
                                            return
                                        }} style={{ 'width': '100%' }} href="#" className="btn input-btn mt-2 mt-md-0 withdraw-btn">
                                            {this.state.reawardLoading && <span>
                                                <i style={{ 'fontSize': '13px', 'marginRight': '8px' }} className="fa fa-spinner loading-css"></i>
                                            </span>}
                                            {this.props.t('领取奖励')}</div>
                                        {/* </div> */}
                                        <div className='withdraw-btn-2'></div>
                                        {/* <div className='withdraw-btn'> */}
                                        <div onClick={() => {
                                            if (this.checkNetwork() === false) {
                                                return
                                            }
                                            // 设置质押信息
                                            this.unStakeModalRef.setStakeInfo({
                                                stakedAmount: this.state.poolInfo.userStakedAmount,
                                                userCanWithdraw: this.state.poolInfo.allowMergencyWithdraw,
                                                stakeTokenInfo: this.state.stakeTokenInfo,
                                                stakeContract: this.state.contract,
                                                stakedAmountBigNumber: this.state.poolInfo.userStakeAmountBigNumber
                                            })
                                                .then(() => {
                                                    this.setState({
                                                        showUnstakeModal: true
                                                    })
                                                })
                                        }} className="btn input-btn mt-2 mt-md-0 withdraw-btn"> {this.props.t('解除质押')}</div>
                                        {/* </div> */}

                                    </div>
                                </div>
                                <span>{this.state.data.content}</span>
                                <span className="mt-3"><strong>{this.state.data.note}</strong></span>

                            </div>
                            {this.state.isAdmin && <div className='card no-hover staking-card mt-4'>
                                <p>{this.props.t('管理员控制')}</p>
                                {this.state.countdown.type !== 3 && <div>
                                    <div onClick={this.stopRewardLogic.bind(this)} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.state.stopRewardLoading && <span>
                                            <i style={{ 'fontSize': '13px', 'marginRight': '8px' }} className="fa fa-spinner loading-css"></i>
                                        </span>}
                                        {this.props.t('立即终止矿池')}
                                    </div>
                                </div>}
                                {/* 已结束1天以上, 允许提取剩余奖励代币 */}
                                {this.state.countdown.type === 3 && (moment().unix() - this.state.poolInfo.endTime.unix()) > 3600 * 24 && <div className='mt-2'>
                                    <div onClick={this.withdrawRemindRewardTokens.bind(this)} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.state.stopRewardLoading && <span>
                                            <i style={{ 'fontSize': '13px', 'marginRight': '8px' }} className="fa fa-spinner loading-css"></i>
                                        </span>}
                                        {this.props.t('提取剩余奖励代币')}
                                    </div>
                                </div>}

                                <div className='mt-2'>
                                    <div onClick={this.enableForceWithdraw.bind(this)} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.state.enableForceWithdrawLoading && <span>
                                            <i style={{ 'fontSize': '13px', 'marginRight': '8px' }} className="fa fa-spinner loading-css"></i>
                                        </span>}
                                        {!this.state.poolInfo.allowMergencyWithdraw ? this.props.t('允许紧急撤离') : this.props.t('禁止紧急撤离')}
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div onClick={() => {
                                        if (this.checkNetwork() === false) return
                                        this.ipfsPanelRef.setIpfsInfo({
                                            ...this.state.ipfsInfo,
                                            // top_bg: 'https://picx.zhimg.com/v2-cba0b89d2bf2d96a1ed26edb5849f804_qhd.jpg?source=172ae18b'
                                        }).then(() => {
                                            this.setState({
                                                showIpfsPanel: true
                                            })
                                        })

                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('设置项目信息')}
                                    </div>
                                </div>
                                {!this.state.poolInfo?.enableInvitor && <div className='mt-2'>
                                    <div onClick={() => {
                                        if (this.checkNetwork() === false) return
                                        this.setState({
                                            showInviteConfirmModal: true
                                        })
                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('开启推广邀请')}
                                    </div>
                                </div>}
                                {this.state.poolInfo?.enableInvitor && <div className='mt-2'>
                                    <div onClick={() => {
                                        if (this.checkNetwork() === false) return
                                        this.setState({
                                            showInviteConfirmModal: true
                                        })
                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('设置推广算力等级')}
                                    </div>
                                </div>}
                                {this.state.poolInfo?.enableInvitor && <div className='mt-2'>
                                    <div onClick={() => {
                                        if (this.checkNetwork() === false) return
                                        this.setState({
                                            showWhitelistModal: true
                                        })
                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('设置白名单')}
                                    </div>
                                </div>}
                                <div className='mt-2'>
                                    <div onClick={() => {
                                        if (this.checkNetwork() === false) return
                                        copy(window.location.href)
                                        this.showMessageModal({
                                            show: true,
                                            title: this.props.t('复制成功'),
                                            html: `<p>${window.location.href} </p>`,
                                            icon: 'success'
                                        })
                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('复制矿池地址')}
                                    </div>
                                </div>
                                {(window.location.href.indexOf('fatsale') > -1 || window.location.href.indexOf('localhost') > -1) && <div className='mt-2'>
                                    <div onClick={() => {
                                        if (this.checkNetwork() === false) return
                                        copy(window.location.href)
                                        this.showMessageModal({
                                            show: true,
                                            title: this.props.t('绑定独立域名'),
                                            html: `<div><p>${this.props.t('平台支持绑定独立域名并部署在单独的服务器中')}</p><p>${this.props.t('此功能需要自行购买域名, 并额外支付部署费用以及后续维护费用')}</p><p>${this.props.t('具体请咨询')} <a target="_blank" href="https://t.me/safu_contract" >${this.props.t('客服')}</a></p></div>`,
                                        })
                                    }} style={{ 'width': '100%' }} className="btn btn-bordered-white btn-smaller">
                                        {this.props.t('绑定独立域名')}
                                    </div>
                                </div>}
                            </div>}
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="staking-items mt-4 mt-md-0">
                                <div className='card no-hover staking-card'>
                                    <h3 className="m-0">{this.state.countdown.ct}</h3>
                                    <span style={{ 'cursor': 'pointer' }} onClick={() => {
                                        this.setState({
                                            showTimeDetail: !this.state.showTimeDetail
                                        })
                                    }} className='count-down-css'>
                                        {this.state.countdown.type === 1 ? this.props.t('即将开始') : ''}
                                        {this.state.countdown.type === 2 ? this.props.t("结束倒计时") : ''}
                                        {this.state.countdown.type === 3 ? this.props.t('已结束') : ''}
                                        {this.state.showTimeDetail &&
                                            <span> <i style={{ 'marginLeft': '5px' }} className="fas fa-angle-double-down"></i></span>}
                                        {!this.state.showTimeDetail && <span> <i style={{ 'marginLeft': '5px' }} className="fas fa-angle-double-right"></i></span>}
                                    </span>
                                    {/* <span>即将开始</span> */}
                                    {/* <span>已结束</span> */}
                                    {this.state.showTimeDetail && <p>
                                        <li><strong>{this.props.t('质押开始时间')}:</strong> {this.state.poolInfo.startTime?.format('YYYY-MM-DD HH:mm')}</li>
                                        {parseFloat(this.state.poolInfo.poolLimitPerUser) > 0 && <li><strong>{this.props.t('质押结束时间')}:</strong> {this.state.poolInfo.deadline?.format('YYYY-MM-DD HH:mm')}</li>}
                                        <li><strong>{this.props.t('矿池结束时间')}:</strong> {this.state.poolInfo.endTime?.format('YYYY-MM-DD HH:mm')}</li>
                                    </p>}
                                </div>

                                {/* 我的邀请链接 */}
                                {this.state.poolInfo?.enableInvitor && <div className='card no-hover staking-card mt-4'>
                                    <div style={{ 'position': 'relative' }}>
                                        <input style={{ 'paddingRight': '65px' }} value={this.generateShareLink()} className="unstake-input" type="text" disabled />
                                        <div className="unstake-input-a">{this.props.t('复制')}</div>
                                        <div onClick={() => {

                                            copy(this.generateShareLink())
                                            this.showMessageModal({
                                                show: true,
                                                title: this.props.t('复制成功'),
                                                html: `<p>${this.generateShareLink()}</p>`,
                                                icon: 'success'
                                            })
                                        }} className="unstake-input-a unstake-input-a-mask">

                                        </div>
                                    </div>
                                    <p>{this.props.t('我的邀请链接')}</p>
                                </div>}

                                {this.state.poolInfo?.myInvitor && <div className='card no-hover staking-card mt-4'>
                                    <div style={{ 'position': 'relative' }}>
                                        <input style={{ 'padding-right': '65px' }} value={this.state.poolInfo.myInvitor} className="unstake-input" type="text" disabled />
                                        <div className="unstake-input-a">{this.props.t('复制')}</div>
                                        <div onClick={() => {

                                            copy(this.state.poolInfo.myInvitor)
                                            this.showMessageModal({
                                                show: true,
                                                title: this.props.t('复制成功'),
                                                html: `<p>${this.state.poolInfo.myInvitor}</p>`,
                                                icon: 'success'
                                            })
                                        }} className="unstake-input-a unstake-input-a-mask">

                                        </div>
                                    </div>
                                    <p>{this.props.t('我的邀请人')}</p>
                                </div>}



                                {this.state?.ipfsInfo?.stakedRule && <div className='card no-hover staking-card mt-4'>
                                    <h5>{this.props.t('质押规则')}</h5>
                                    <span>{this.state.ipfsInfo.stakedRule}</span>
                                </div>}

                                {/* <div className='card no-hover staking-card mt-4'>
                                    <h5> <a style={{ color: '#9CA0D2' }} _target="_blank" href="/#/stake-one">{this.props.t('测试矿池提取 >>')}</a> </h5>
                                </div> */}

                                {this.state.ipfsInfo?.extra?.length > 0 && this.state.ipfsInfo.extra.map(item => {
                                    return <div key={'ext-' + item.name} className='card no-hover staking-card mt-4'>
                                        <h5>{item.name}</h5>
                                        <span>{item.content}</span>
                                    </div>
                                })}
                                {/* {this.state.ipfsInfo.youtube && <div style={{ 'padding': '13px' }} className='mt-4 card no-hover staking-card'>
                                    <iframe className='card-img-top' width="100%" height="260" src={this.state.ipfsInfo.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>} */}

                                {this.state.ipfsInfo.youtube && <div style={{ 'padding': '13px' }} className='mt-4 card no-hover staking-card'>
                                    <iframe className='card-img-top' width="100%" height="260" src={this.state.ipfsInfo.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>}



                            </div>

                        </div>
                        {this.state.isAdmin && <div style={{ 'width': '100%' }} className='card no-hover staking-card mt-4'>
                            <h5 style={{ 'margin': '0' }}>{this.props.t('管理员数据面板')}</h5>
                            <div className='mt-2 d-flex flex-row' style={{ 'flexWrap': 'wrap' }} >
                                <div>{this.props.t('质押用户数')}: {this.state.adminInfo.userCount}</div> &nbsp;&nbsp;
                                <div>{this.props.t('质押总量')}: {this.state.adminInfo.totalStakeAmount / Math.pow(10, this.state.stakeTokenInfo.decimals)}</div>&nbsp;&nbsp;
                                <div>{this.props.t('当前总算力')}: {this.state.adminInfo.totalPower / Math.pow(10, this.state.stakeTokenInfo.decimals)}</div>&nbsp;&nbsp;
                            </div>
                            <div style={{ 'position': 'relative' }}>
                                <UserListTable
                                    key={'total-list-table'}
                                    stakeDecimals={this.state.stakeTokenInfo.decimals}
                                    rewardDecimals={this.state.rewardTokenInfo.decimals}
                                    total={this.state.adminInfo.userCount}
                                    // total={100}
                                    perPage={this.state.perPage}
                                    list={this.state.stakeUserList}
                                    changePage={(page) => {
                                        this.getStakeListForAdmin(page)
                                    }}
                                    copy={(addr) => {
                                        copy(addr)
                                        this.showMessageModal({
                                            show: true,
                                            title: this.props.t('复制成功'),
                                            html: `<p>${addr}</p>`,
                                            icon: 'success'
                                        })
                                    }}
                                />
                            </div>
                        </div>}
                        {this.state.invitorTotalCount > 0 && <div style={{ 'width': '100%' }} className='card no-hover staking-card mt-4'>
                            <h5 style={{ 'margin': '0' }}>{this.props.t('我的邀请列表')}</h5>
                            <div className='mt-2 d-flex flex-row' style={{ 'flexWrap': 'wrap' }} >
                                <div>{this.props.t("已邀请用户数")}: {this.state.invitorTotalCount}</div> &nbsp;&nbsp; <div>{this.props.t('已提升算力')}: {this.state.poolInfo.userExtendPownerAmount / Math.pow(10, this.state.stakeTokenInfo.decimals)}</div>
                            </div>
                            <div style={{ 'position': 'relative' }}>
                                <UserListTable
                                    key={'user-list-table'}
                                    stakeDecimals={this.state.stakeTokenInfo.decimals}
                                    rewardDecimals={this.state.rewardTokenInfo.decimals}
                                    total={this.state.invitorTotalCount}
                                    // total={100}
                                    perPage={this.state.perPage}
                                    list={this.state.invitorList}
                                    changePage={(page) => {
                                        this.getInvitorList(page)
                                    }}
                                    copy={(addr) => {
                                        copy(addr)
                                        this.showMessageModal({
                                            show: true,
                                            title: this.props.t('复制成功'),
                                            html: `<p>${addr}</p>`,
                                            icon: 'success'
                                        })
                                    }}
                                />
                            </div>
                        </div>}
                    </div>
                </div>
            </section >
        );
    }
}

// export default StakingOne;

function mapStateToProps(state) {
    const { account, chainId } = state;
    return {
        account,
        chainId
        // text
    };
}

// export default ProjectSingle;
export default connect(mapStateToProps)(withTranslation()(Staking0421))

