import React, { useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useHistory } from "react-router-dom";
import SuccessSvg from "../SuccessSvg/index";
import { ethers } from "ethers";
import { MiningPoolAbi, tokenAbi } from "../../../../utils/config";
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import { zeroAddress } from "../../../../utils/config";


export default function StakeModal(props) {
    const [approveAmountInfo, setApproveAmountInfo] = useState({}); // 授权数量
    const [currentProvider, setCurrentProvider] = useState(null); // 当前的provider
    const [currentStep, setCurrentStep] = useState(1); // 当前的步骤
    const [approveLoading, setApproveLoading] = useState(false); // 授权loading
    const [stakeLoading, setStakeLoading] = useState(false); // 质押loading
    const [errorMsg, setErrorMsg] = useState(''); // 错误信息
    // useImperativeHandle(props.)
    const { t } = useTranslation();

    useImperativeHandle(props.onRef, () => {
        // 需要将暴露的接口返回出去
        return {
            setApproveInfo: setApproveInfo,
            handleLogic: handleLogic
        };
    });

    const handleLogic = async () => {
        // 1. 授权
        await handleApproveLogic()
        // 2. 质押
        await handleStakeLogic()
        // 3. 显示完成
    }

    const initProvider = () => {
        if (currentProvider) {
            return currentProvider
        }
        if (window.ethereum && !currentProvider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            setCurrentProvider(provider)
            return provider
        } else {
            console.log('No provider')
        }
    }

    const handleStakeLogic = async () => {
        // setCurrentStep(2)
        setErrorMsg('')
        const p = initProvider()
        setStakeLoading(true)
        // const approveAmount = ethers.utils.parseUnits(approveAmountInfo.amount + '', approveAmountInfo.tokenDecimals)
        let approveAmount = approveAmountInfo.amountBigNumber
        const a = new ethers.Contract(approveAmountInfo.stakeContract, MiningPoolAbi, p.getSigner())
        console.log(zeroAddress)
        // 如果质押数量大于余额, 则直接设置为余额
        const stakeTokenBalance = approveAmountInfo.stakeTokenBalanceBigNumber
        if (approveAmount.gt(stakeTokenBalance)) {
            approveAmount = stakeTokenBalance
            console.log('质押数量大于余额, 设置为余额', approveAmount.toString())
        }
        try {
            const tx = await a.deposit(approveAmount, (props.invitor && props.invitor !== undefined) ? props.invitor : zeroAddress)
            console.log('发起质押', tx)
            const receipt = await tx.wait()
            console.log('质押成功', receipt)
            props.ok && props.ok()
            setCurrentStep(3)
        } catch (e) {
            handleErr(e)
        }
        setStakeLoading(false)
    }

    const handleApproveLogic = async () => {
        setCurrentStep(1)
        setErrorMsg('')
        const p = initProvider()
        // 判断授权数量是否正确
        console.log(approveAmountInfo)
        setApproveLoading(true)
        // 如果是stakeMax 则获取当前余额, 否则使用输入的数量
        // const approveAmount = ethers.utils.parseUnits(approveAmountInfo.amount + '', approveAmountInfo.tokenDecimals)
        const approveAmount = approveAmountInfo.amountBigNumber
        const a = new ethers.Contract(approveAmountInfo.tokenAddress, tokenAbi, p.getSigner())
        console.log(approveAmountInfo, a, p)
        // 如果正确, 则返回, 如果错误, 则发起授权
        const allowanceAmount = await a.allowance(approveAmountInfo.currentWallet, approveAmountInfo.stakeContract)
        console.log('授权数量为', allowanceAmount, allowanceAmount.toString(), approveAmount, approveAmount.toString())
        if (approveAmount.gt(allowanceAmount)) { // 授权数量不够
            // 发起授权
            try {
                const tx = await a.approve(approveAmountInfo.stakeContract, approveAmount)
                console.log('发起授权', tx)
                const receipt = await tx.wait()
                console.log('授权成功', receipt)
                setCurrentStep(2)
            } catch (e) {
                setCurrentStep(1)
                handleErr(e)
            }

        } else {
            // 授权数量足够
            setCurrentStep(2)
        }
        setApproveLoading(false)
    }

    const handleErr = (e) => {
        console.log(e)
        let tt = ''
        if (e.reason) {
            tt = e.reason
        } else {
            tt = JSON.stringify(e)
        }
        if (tt.indexOf('not wl') > -1) {
            tt = t('邀请用户暂未质押代币, 无法绑定上下级关系')
        }
        setErrorMsg(tt)
    }

    const setApproveInfo = (info) => {
        return new Promise((resolve, reject) => {
            setApproveAmountInfo(info)
            // if (info.stakeMax) {
            //     // 质押最大, 获取当前的余额

            // } else {
            //     // 质押的不是最大, 使用输入的数量计算
            // }
            resolve()
        })
        //  
    }


    return <>
        <Modal centered show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >

            <Modal.Body>
                <div style={{ 'width': '100%' }}>
                    <div style={{ 'width': '100%' }}>
                        <div className="stake-modal-bar">
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 1 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">1</div>
                                <div className="stake-modal-bar-item-title"> {t('授权')}</div>
                            </div>
                            <div className={"stake-modal-bar-item-solid " + (currentStep > 1 ? 'stake-modal-bar-item-solid-success' : '')}></div>
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 2 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">2</div>
                                <div className="stake-modal-bar-item-title"> {t('质押U')}</div>
                            </div>
                            <div className={"stake-modal-bar-item-solid " + (currentStep > 2 ? 'stake-modal-bar-item-solid-success' : '')}></div>
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 3 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">3</div>
                                <div className="stake-modal-bar-item-title"> {t('完成')}</div>
                            </div>
                        </div>
                    </div>
                    {currentStep === 1 && <div className='stake-modal-body'>
                        <div style={{ 'marginTop': '20px', 'marginBottom': '20px' }}>
                            {approveLoading && <div>
                                <i style={{ 'fontSize': '50px' }} className="fa fa-spinner loading-css"></i></div>}
                            {errorMsg && <Alert variant={'danger'}>
                                {errorMsg}
                            </Alert>}
                        </div>

                        <div onClick={() => {
                            if (approveLoading) {
                                return
                            }
                            handleApproveLogic()
                        }} className="btn btn-bordered-white btn-smaller">
                            {approveLoading && t('授权中...')}
                            {!approveLoading && t('授权')}
                        </div>
                    </div>}
                    {currentStep === 2 && <div className='stake-modal-body'>
                        <div style={{ 'marginTop': '20px', 'marginBottom': '20px' }}>
                            {stakeLoading && <div>
                                <i style={{ 'fontSize': '50px' }} className="fa fa-spinner loading-css"></i></div>}
                            {errorMsg && <Alert variant={'danger'}>
                                {errorMsg}
                            </Alert>}
                        </div>
                        {(props.invitor && props.invitor !== undefined) && <div style={{ 'width': '100%', 'overflow': 'hidden' }}>
                            <Alert variant={'success'} className="d-flex align-items-center">
                                <div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        {t('邀请人')}
                                    </div>
                                    <div>
                                        {props.invitor}
                                    </div>
                                </div>

                            </Alert>
                        </div>}
                        <div onClick={() => {
                            if (stakeLoading) {
                                return
                            }
                            setCurrentStep(2)
                            handleStakeLogic()
                        }} className="btn btn-bordered-white btn-smaller">
                            {stakeLoading && t('质押中...')}
                            {!stakeLoading && t('质押')}
                        </div>
                    </div>}
                    {currentStep === 3 && <div className='stake-modal-body'>
                        <div style={{ 'marginTop': '20px', 'marginBottom': '20px' }}>
                            <div>
                                <SuccessSvg /></div>
                        </div>

                        <div onClick={() => {
                            // 质押完成, 点击关闭
                            props.handleClose && props.handleClose()
                        }} className="btn btn-bordered-white btn-smaller">
                            {t('质押完成')}
                        </div>
                    </div>}
                </div>



            </Modal.Body>
        </Modal>
    </>
}