import React, { Component } from 'react';
import { connect } from "react-redux"
class Breadcrumb extends Component {
    render() {
        return (
            <section style={{ 'backgroundImage': `url("${this.props?.miningInfo?.top_bg || '/img/inner_bg.jpeg'}")` }} className="breadcrumb-area overlay-dark d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                <h2 onClick={() => {
                                    console.log(this.props)
                                }} className="m-0">{this.props.miningInfo?.project_name}</h2>
                                <div className="breadcrumb d-flex justify-content-center">
                                    {this.props.miningInfo?.description}

                                </div>
                                <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item">{this.props.desc}</li>
                                    {/* <li className="breadcrumb-item"><a href="#">{this.props.subpage}</a></li>
                                    <li className="breadcrumb-item active">{this.props.page}</li> */}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


function mapStateToProps(state) {
    const miningInfo = state.miningInfo;
    return {
        miningInfo,
        // text
    };
}

// export default ProjectSingle;
export default connect(mapStateToProps)(Breadcrumb)

// export default Breadcrumb;