
import React, { useEffect } from "react";

export default function StakeModal(props) {
    return <>
        <div class="svg-container">
            <svg class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="80" width="80" viewBox="0 0 48 48" aria-hidden="true">
                <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22" />
                <path class="tick" fill="none" stroke="#FFF" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M14 27l5.917 4.917L34 17" />
            </svg>
        </div>
    </>
}