import React from 'react';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import store from './store/index'
import { Provider } from 'react-redux'

function getLibrary(provider) {
  return new Web3(provider)
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <div>
          <MyRouts />
        </div>
      </Provider>
    </Web3ReactProvider>

  );
}

export default App;