import React, { useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ethers } from "ethers";
import { MiningPoolAbi, tokenAbi } from "../../../../utils/config";
import { eventBus } from "../../../../utils/tool";
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export default function StakeModal(props) {
    const [errorMsg, setErrorMsg] = useState(''); // 错误信息
    const [currentProvider, setCurrentProvider] = useState(null); // 当前的provider
    const [currentTab, setCurrentTab] = useState('add'); // 当前的tab
    const [addressInput, setAddressInput] = useState(''); // 地址输入
    const [submitLoading, setSubmitLoading] = useState(false); // 提交loading

    const { t } = useTranslation();
    useImperativeHandle(props.onRef, () => {
        // 需要将暴露的接口返回出去
        return {
        };
    });



    const initProvider = () => {
        if (currentProvider) {
            return currentProvider
        }
        if (window.ethereum && !currentProvider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            setCurrentProvider(provider)
            return provider
        } else {
            console.log('No provider')
        }
    }


    const handleErr = (e) => {
        console.log(e)
        let t = ''
        if (typeof e === 'string') {
            setErrorMsg(e)
        } else {
            if (e.reason) {
                t = e.reason
            } else {
                t = JSON.stringify(e)
            }
            setErrorMsg(t)
        }

    }



    return <>
        <Modal centered show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            <Modal.Header closeButton>
                <Modal.Title>{t('设置白名单')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Alert>
                        <div>
                            {t('白名单账号无需质押, 即可享受下级推荐算力提升, 非白名单必须质押后才可邀请下级享受算力提升')}
                        </div>
                    </Alert>
                    <div>
                        <Tabs
                            activeKey={currentTab}
                            onSelect={(k) => setCurrentTab(k)}
                            className="mb-3"
                        >
                            <Tab
                                tabClassName="custom-whitelist-tab"
                                eventKey="add" title={t('添加白名单')}>
                                <div>
                                    <Form.Control value={addressInput} onChange={(e) => {
                                        setAddressInput(e.target.value)
                                    }} className="ipfs-info-input" as="textarea" placeholder={t('输入要设置的钱包地址, 每行一个地址')} rows={5} />
                                </div>
                            </Tab>
                            <Tab
                                tabClassName="custom-whitelist-tab"
                                eventKey="remove" title={t('移除白名单')}>
                                <div>
                                    <Form.Control value={addressInput} onChange={(e) => {
                                        setAddressInput(e.target.value)
                                    }} className="ipfs-info-input" as="textarea" placeholder={t('输入要设置的钱包地址, 每行一个地址')} rows={5} />

                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                    <div className="mt-2">
                        <Alert variant="danger" show={errorMsg ? true : false} >
                            {errorMsg}
                        </Alert>
                    </div>
                    <div className="mt-2 d-flex flex-row align-items-center   justify-content-center">
                        <div onClick={async () => {
                            setErrorMsg('')

                            // 提交
                            // 验证地址是否正确
                            let x = ''
                            let arr = []
                            const xx = addressInput.split('\n')
                            for (let i = 0; i < xx.length; i++) {
                                if (!xx[i]) continue
                                if (xx[i].length !== 42) {
                                    x = t('第1行的地址格式不正确').replace('{line}', i + 1)
                                    handleErr(x)
                                    return
                                }
                                if (xx[i].indexOf('0x') !== 0) {
                                    x = t('第1行的地址格式不正确').replace('{line}', i + 1)
                                    handleErr(x)
                                    return
                                }
                                arr.push(xx[i])
                            }
                            // 准备提交
                            setSubmitLoading(true)
                            const provider = initProvider()
                            const signer = provider.getSigner()
                            try {
                                const contract = new ethers.Contract(props.poolAddress, MiningPoolAbi, signer)
                                const tx = await contract.multiWLs(arr, currentTab === 'add' ? true : false)
                                await tx.wait()
                                props.ok && props.ok()

                            } catch (e) {
                                handleErr(e)
                            }

                            setSubmitLoading(false)
                        }} className="ml-2 btn btn-bordered-white btn-smaller d-flex flex-row align-items-center   justify-content-center">
                            {submitLoading && <span>
                                <i style={{ 'fontSize': '13px', 'marginRight': '8px' }} className="fa fa-spinner loading-css"></i>
                            </span>}
                            {t('提交')}
                        </div>
                    </div>
                </div>


            </Modal.Body>
        </Modal>
    </>
}