import React, { useEffect, useState } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector'
import { useWeb3React } from "@web3-react/core"
import { useSelector } from "react-redux";
import store from '../../store';
import { eventBus } from '../../utils/tool';
import { useTranslation } from 'react-i18next';
import { useFavicon } from 'ahooks'


import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum'
import {  Web3Modal } from '@web3modal/react'
import { configureChains, createClient } from 'wagmi'
import { arbitrum, avalanche, bsc, fantom, gnosis, mainnet, optimism, polygon } from 'wagmi/chains'

const projectId = '7a79fc234c711ac43535858fa69984a8'
const chains = [mainnet, polygon, avalanche, arbitrum, gnosis, bsc, optimism, fantom]
const { provider } = configureChains(chains, [walletConnectProvider({ projectId })])
const wagmiClient = createClient({
    autoConnect: true,
    connectors: modalConnectors({ version: '1', appName: 'web3Modal', chains, projectId }),
    provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)


const injected = new InjectedConnector({
    // supportedChainIds: [1, 56, 97],
})



const Header = () => {
    const { t, i18n } = useTranslation();

    // const [currentWallet, setCurrentWallet] = useState('')


    const changeLaungue = (lng) => {
        i18n.changeLanguage(lng)
    }






    const { active, chainId, account, library, connector, activate, deactivate } = useWeb3React()

    const [stakedTokenLogo, setStatedTokenLogo] = useState('')
    const miningInfo = useSelector(state => state.miningInfo);
    console.log(miningInfo, 'miningInf222o')
    // const dispatch = useDispatch()
    useEffect(() => {
        if (miningInfo?.stakedTokenLogo) {
            // useFavicon(miningInfo.stakedTokenLogo)
            setStatedTokenLogo(miningInfo.stakedTokenLogo)
        }
    }, [miningInfo])

    useFavicon(stakedTokenLogo)

    // console.log(miningInfo, 'iiiii')



    const walletConnectLogic = async () => {
        if (account) {
            console.log('断开链接')
            return
        }
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    useEffect(() => {
        walletConnectLogic()
    }, [])

    useEffect(() => {
        console.log('账户更新', account)
        store.dispatch({ type: "setAccount", value: account })
        store.dispatch({ type: "setChainId", value: chainId })
        eventBus.emit("walletConnect", { account })
    }, [account, chainId])

    const getShortAddress = (address) => {
        return address.slice(0, 4) + "..." + address.slice(address.length - 5);
    }
    return (
        <header id="header">
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar gameon-navbar navbar-expand">
                <div className="container header">
                    {/* <a className="navbar-brand" href="/">
                        <img src="/img/logo.png" alt="Brand Logo" />
                    </a> */}
                    <div className="ml-auto" />
                    {/* Navbar Nav */}
                    {/* <ul className="navbar-nav items mx-auto">
                        <li className="nav-item">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link">Projects <i className="icon-arrow-down" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href="/project-one" className="nav-link">Project Style 1</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-two" className="nav-link">Project Style 2</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-three" className="nav-link">Project Style 3</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-four" className="nav-link">Project Style 4</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/project-single" className="nav-link">Project Single</a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link">Staking <i className="icon-arrow-down" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href="/staking-one" className="nav-link">Staking Style 1</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/staking-two" className="nav-link">Staking Style 2</a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link">Pages <i className="icon-arrow-down" /></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href="/farming" className="nav-link">Farming</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/leaderboard" className="nav-link">Leaderboard</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link">Inner Pages <i className="icon-arrow-right" /></a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <a href="/apply" className="nav-link">Apply for Project</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/wallet-connect" className="nav-link">Wallet Connect</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/help-center" className="nav-link">Help Center</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/contact" className="nav-link">Contact</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link">Auth Pages <i className="icon-arrow-right" /></a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <a href="/login" className="nav-link">Login</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/register" className="nav-link">Register</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/reset" className="nav-link">Forgot Password</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a href="/tokenomics" className="nav-link">Tokenomics</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/tier-system" className="nav-link">Tier System</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link">Community <i className="icon-arrow-right" /></a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <a href="/blog" className="nav-link">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/blog-single" className="nav-link">Blog Single</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href="/contact" className="nav-link">Contact</a>
                        </li>
                    </ul> */}
                    {/* Navbar Icons */}
                    {/* <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#search">
                                <i className="icon-magnifier" />
                            </a>
                        </li>
                    </ul> */}
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                {/* <i className="icon-menu m-0" /> */}
                                {/* <i style={{ 'fontSize': '27px' }} class="fa fa-language"></i> */}
                                <i style={{ 'fontSize': '24px' }} className="fa fa-globe-asia"></i>
                            </a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a href='#' onClick={() => { changeLaungue('en') }} style={{ 'fontSize': '13px' }} className="nav-link">English</a>
                                </li>
                                <li className="nav-item">
                                    <a href='#' onClick={() => { changeLaungue('zh') }} style={{ 'fontSize': '13px' }} className="nav-link">简体中文</a>
                                </li>

                            </ul>
                        </li>
                    </ul>
                    {/* Navbar Action Button */}

                    <ul className="navbar-nav action">
                        {/* <li className="nav-item ml-2">
                            <WagmiConfig client={wagmiClient}>
                                <Web3Button />
                                <br />
                                <Web3NetworkSwitch />
                                <br />
                            </WagmiConfig>
                        </li> */}
                        <li className="nav-item ml-2 dropdown">
                            <span href="/wallet-connect" onClick={
                                walletConnectLogic
                            } style={{ 'fontSize': '14px', }} className="btn ml-lg-auto btn-bordered-white wallet-connect-btn">
                                <i style={{ 'marginRight': '5px' }} className="icon-wallet mr-md-2" />
                                {account ? getShortAddress(account) : "Wallet Connect"}
                            </span>
                            {account && <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <div onClick={() => {
                                        // console.log("disconnect");
                                        // disconnectWallet();
                                        deactivate() ?? injected?.close();

                                    }} style={{ 'fontSize': '13px' }} className="nav-link"> {t('断开连接')} </div>
                                </li>
                            </ul>}
                        </li>
                    </ul>
                </div>
            </nav>
            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />

        </header>

    );
};

export default Header;